import JourneyControllerService from '@/services/JourneyControllerService';
import ComponentListModel from '@/models/Components/ComponentListModel';
import { componentList, errorComponent } from './ComponentList';
import LooseObject from '@/models/Objects/LooseObject';
import FormFieldModel from '@/models/Objects/FormFieldModel';
import ComponentControllerResponse from '@/models/Components/ComponentControllerResponse';
import MessageBubbleModel from '@/models/Components/MessageBubble/MessageBubbleModel';
import store from '@/store';
import { EventBus } from '@/utilities/eventBus/EventBus';
import { extractMessageHistory } from '@/utilities/mutate';

export default class ComponentController {
    private service: JourneyControllerService;

    constructor(service: JourneyControllerService) {
        this.service = service;
    }

    public async getComponentTypes(
        formName: string,
        workflowName: string,
        formData: LooseObject,
        actionId?: number,
        resumeHeader?: LooseObject,
    ): Promise<ComponentControllerResponse> {

        const response = await this.service.getJourneyResponse(
            formName,
            workflowName,
            formData,
            actionId,
            resumeHeader,
        );
        if (Object.keys(formData).length === 0) {
            store.commit('updateFormHistory', []);
            store.commit('updateMessages', []);
        }
        if (response.formHistory !== (undefined || null)) {
            store.commit('updateFormHistory', response.formHistory);
            store.commit('updateMessages', extractMessageHistory(response.formHistory));
        }
        store.commit('addForm', response.form);
        this.displayNewMessage(response.form.text);
        return {
            formName: response.form.name,
            workflowName: response.form.workflow,
            actionId: response.form.actionId,
            submittedMessageText: response.form.submittedMessageText,
            sideMenu: response.form.sideMenu,
            topMenu: response.form.topMenu,
            autoRefresh: response.form.autoRefresh,
            noEdit: response.form.noEdit,
            clearApplicant: response.form.clearApplicant,
            components: this.extractComponents(response.form.formFields),
        };
    }

    public extractComponents(response: FormFieldModel[]): ComponentListModel[] {
        const components: ComponentListModel[] = response.map((val) => {
            return this.buildComponent(val) as ComponentListModel;
        });
        return components;
    }

    public displayNewMessage(message: string) {
        const newMessage: MessageBubbleModel = {
            userMessage: false,
            elementId: `message-bubble-${store.state.messages.length + 1}`,
            messages: [{
                elementId: `message-${store.state.messages.length + 1}`,
                value: message || '',
            }],
            disabled: false,
        };
        store.commit('addMessage', newMessage);
        EventBus.$emit('renderNewMessage', false);
    }

    private buildComponent(val: any) {
        switch (val.type) {
            case 'input_button_radio':
            case 'input_radio': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    options: val.options,
                    label: val.text,
                    attributes: {
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                        orientation: val.attributes?.orientation,
                    },
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                    },
                };
                break;
            }
            case 'input_checkbox': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    options: val.options,
                    defaultOption: val.defaultOptions,
                    label: val.text,
                    attributes: {
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                        checkAll: val.attributes?.checkAll,
                        orientation: val.attributes?.orientation,
                    },
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                    },
                };
                break;
            }
            case 'title': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    content: val.text,
                    component: component.component,
                    elementId: val.name,
                };
                break;
            }
            case 'text': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    content: val.attributes?.content,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        unit: val.attributes?.unit,
                        locale: val.attributes?.locale,
                        decimals: val.attributes?.decimals,
                        currency: val.attributes?.currency,
                        value: val.attributes?.value,
                    },
                };
                break;
            }
            case 'input_submit': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    content: val.text,
                    attributes: {
                        value: val.value,
                    },
                    component: component.component,
                    elementId: val.name,
                    isEdit: false,
                    previousForm: '',
                    previousFormName: '',
                    previousWorkflowName: '',
                };
                break;
            }
            case 'address_line':
            case 'address_city':
            case 'address_county':
            case 'address_postcode': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                    },
                    validations: {
                        minLength: val.attributes?.minLength,
                        maxLength: val.attributes?.maxLength,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_text': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                        password: val.attributes?.password,
                        visibility: val.attributes?.visibility,
                        textBox: val.attributes?.textBox,
                        textBoxHeight: val.attributes?.textBoxHeight,
                    },
                    validations: {
                        minLength: val.attributes?.minLength,
                        maxLength: val.attributes?.maxLength,
                        pattern: val.attributes?.pattern,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_sso': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    content: val.attributes?.content,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        provider: val.attributes?.provider,
                    },
                };
                break;
            }
            case 'input_address': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                        buildingName: val.attributes?.buildingName,
                        buildingNumber: val.attributes?.buildingNumber,
                        street: val.attributes?.street,
                        town: val.attributes?.town,
                        county: val.attributes?.county,
                        country: val.attributes?.country,
                        subBuildingName: val.attributes?.subBuildingName,
                        subBuildingNumber: val.attributes?.subBuildingNumber,
                        postcode: val.attributes?.postcode,
                        provider: val.attributes?.provider,
                        lookupType: val.attributes?.lookupType,
                        countryLimitCode: val.attributes?.countryLimitCode,
                    },
                    validations: {
                        minLength: val.attributes?.minLength,
                        maxLength: val.attributes?.maxLength,
                        pattern: val.attributes?.pattern,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_select': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    options: val.options,
                    searchable: val.searchable,
                    attributes: {
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                        searchable: val.attributes?.searchable,
                    },
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                    },
                };
                break;
            }
            case 'input_phone': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                    },
                    validations: {
                        minLength: val.attributes?.minLength,
                        maxLength: val.attributes?.maxLength,
                        pattern: val.attributes?.pattern,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_date': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        locale: val.attributes?.locale,
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                        format: val.attributes?.format,
                    },
                    validations: {
                        minDate: val.attributes?.minDate,
                        maxDate: val.attributes?.maxDate,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_email': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        placeholder: val.attributes?.placeholder,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                    },
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'amount_slider':
            case 'term_slider': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        unit: val.attributes?.unit,
                        locale: val.attributes?.locale,
                        decimals: val.attributes?.decimals,
                        currency: val.attributes?.currency,
                        stepIncrement: val.attributes?.stepIncrement,
                        ariaLabel: val.attributes?.ariaLabel,
                        value: val.attributes?.value,
                    },
                    validations: {
                        minValue: val.attributes?.minValue,
                        maxValue: val.attributes?.maxValue,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'income':
            case 'expense':
            case 'input_number': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        ariaLabel: val.attributes?.ariaLabel,
                        unit: val.attributes?.unit,
                        locale: val.attributes?.locale,
                        decimals: val.attributes?.decimals,
                        currency: val.attributes?.currency,
                        placeholder: val.attributes?.placeholder,
                        value: val.attributes?.value,
                    },
                    validations: {
                        minValue: val.attributes?.minValue,
                        maxValue: val.attributes?.maxValue,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_file': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        fileName: val.attributes?.fileName,
                        fileType: val.attributes?.fileType,
                        placeholder: val.attributes?.placeholder,
                        value: val.attributes?.value,
                    },
                    validations: {
                        maxSize: val.attributes?.maxSize,
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'input_hidden': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.attributes?.ariaLabel,
                    attributes: {
                        value: val.attributes?.value,
                    },
                };
                break;
            }
            case 'input_tokenex_card': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    label: val.text,
                    attributes: {
                        authenticationKey: val.authenticationKey,
                        timestamp: val.timestamp,
                        tokenExId: val.tokenExId,
                        ariaLabel: val.attributes?.ariaLabel,
                        placeholder: val.attributes?.placeholder,
                        includeCvv: val.includeCvv,
                        cvvLabel: val.cvvLabel,
                        cvvPlaceholder: val.cvvPlaceholder,
                    },
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                        validationMessage: val.attributes?.validationMessage,
                    },
                };
                break;
            }
            case 'image': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    attributes: {
                        href: val.attributes?.href,
                        ariaLabel: val.attributes?.ariaLabel,
                        maxWidth: val.attributes?.maxWidth,
                        maxHeight: val.attributes?.maxHeight,
                        unit: val.attributes?.unit,
                    },
                };
                break;
            }
            case 'pdf': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    value: val.attributes?.value,
                    label: val.text,
                    attributes: {
                        ariaLabel: val.attributes?.ariaLabel,
                        b64: val.attributes?.b64,
                        fileName: val.attributes?.fileName,
                    },
                };
                break;
            }
            case 'output_files_download':
            case 'output_files': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    value: val.attributes?.value,
                    label: val.label,
                    files: val.files,
                };
                break;
            }
            case 'output_table': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    tableColumns: val.tableColumns,
                    actionColumns: val.actionColumns,
                    tableData: val.tableData,
                    label: val.text,
                    ariaLabel: val.attributes?.ariaLabel,
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                    },
                    attributes: {
                        deleteable: val.attributes?.deleteable,
                    },
                };
                break;
            }
            case 'output_widget': {
                const component = componentList.find((c) => val.type === c.ref);
                if (!component) {
                    return errorComponent;
                }
                return {
                    ref: component.ref,
                    component: component.component,
                    elementId: val.name,
                    widgets: val.widgets,
                    label: val.text,
                    attributes: {
                        value: val.attributes?.value,
                    },
                    validations: {
                        required: val.required,
                        requiredMessage: val.attributes?.requiredMessage,
                    },
                };
                break;
            }
            default: {
                return errorComponent;
                break;
            }
        }
    }
}
